import Axios from 'axios';
import React, { Component ,Fragment } from 'react';
import LS_SERVICE from '../../../utils/localStorage';
import qs from 'qs';
import {
  LOADER_RED,
  LOADER,
  SUBJECTIVE_INFRMATION_CLINICAL_NOTES,
  SUBJECTIVE_STORE_CLINICAL_NOTES,
  PATIENT_CLINICAL_NOTES,
  PDF_CONFIG_CLINICAL_NOTES_UPDATE,
  PDF_CONFIG_VITALS_GRAPH,
  PATIENT_NOTES_DELETE,
  PATIENT_NOTES_UPDATE,
  HANDLEVALIDANTMESSAGESPATIENTAGE
} from '../../../utils/constant';
import { notification ,Modal,Form ,Input ,Button } from "antd";
import { Link } from 'react-router-dom';
import { headerService$, practiceService, speechService, speechTextService } from '../../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../../pages/speech-to-text/speech-recognition";
import { jQueryLabels, FixedCta } from '../../../utils/jQueryLabels';
import Moment from 'react-moment';
import { subjectiveFieldService } from '../../../utils/rxjs-sharing';
import debounce from 'lodash/debounce';
const { TextArea } = Input;

class ClinicalNotes extends Component {

  constructor(props) {
		super(props);
		this.state = {
      detect_change: false,
      syncing_to_server: false,
      visitDataLoaded: false,
      patient : {
        hospital_id   : LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
        patient_id    : LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
        qms_token_id  : LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      },
      hospital_id: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
      patient_id: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      reference_id: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      allowEdit: props.allowEdit,
      clinical_notes: '',
      clinical_notes_date: '',
      clinical_notes_id: null,
      clinicalNotesInfo: [],
      all_print: [],
      speech: {
        identifier: null,
        state: false
      },
      checkForEditsTrainingData: {
        "chief_complaint": undefined,
        "review_of_systems": undefined
      },
      spin_loading: false,
      clinical_notes_data: {},
      deleteClinicalNotesModal: false,
      editClinicalNotesModal: false,
		};
    this.closeModalPopup = this.closeModalPopup.bind(this);
    this.updateClinicalNotes = this.updateClinicalNotes.bind(this);
    this.handleSubjectiveFormSubmit = debounce(this.handleSubjectiveFormSubmit, 1000);
	}

  async deleteClinicalNotes() {
    let PARAMS = {
      type: 2,
      note_id: this.state.clinical_notes_data.id
    }
    Axios.post(PATIENT_NOTES_DELETE, PARAMS).then(() => {
      this.getClinicalNotes();
      this.closeModalPopup();
      notification.success({
        message: 'Clinical notes deleted successfully',
        placement: 'topRight'
      });
    });
  }

  editNotes(element) {
    this.setState({
      clinical_notes_data: element,
      editClinicalNotesModal: true
    })
  }

  deleteNotes(element) {
    this.setState({
      clinical_notes_data: element,
      deleteClinicalNotesModal: true
    })
  }

  closeModalPopup() {
    this.setState({
      clinical_notes_data: {},
      deleteClinicalNotesModal: false,
      editClinicalNotesModal: false
    })
  }

  updateClinicalNotes(values) {
    let PARAMS = {
      type: 2,
      note_id: this.state.clinical_notes_data.id,
      description: values.internal_note
    }
    Axios.post(PATIENT_NOTES_UPDATE, PARAMS).then(() => {
      this.getClinicalNotes();
      this.closeModalPopup();
      notification.success({
        message: 'Clinical notes updated successfully',
        placement: 'topRight'
      });
    });
  }


  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }

  async componentWillUnmount() {
    this.subjectiveFieldServiceSubscription?.unsubscribe();
    this?.subscription?.unsubscribe();
    // if(this.state.detect_change){
      //await this.handleSubjectiveFormSubmit();
    // }
  }

  async componentDidUpdate() {
    setTimeout(() => {
      jQueryLabels();
      FixedCta();
      let Elem = document.querySelectorAll('.subjective-field');
      Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
    }, 100);
    if(this.state.detect_change && !this.state.syncing_to_server){
      await this.handleSubjectiveFormSubmit();
    } 
  }

  async componentDidMount() {

    this.subjectiveFieldServiceSubscription = subjectiveFieldService.status().subscribe(async data => {
      await this.handleSubjectivesData();
    });

    await this.handleSubjectivesData();
    this.subscription = speechTextService.status().subscribe(async data => {
      const { patient } = this.state;
      if (this.state.speech.state) {
          if (this.state.speech.identifier === 'clinical_notes') {
            this.setState({ clinical_notes: data.text, detect_change: true }, () => { /* this.handleConfirmBtnStatus() */ });
            LS_SERVICE.set("clinical_notes_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
            document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
          }
      }
    });
    this.getClinicalNotes();

    /* setInterval(() => { 
      if(this.state.detect_change && !this.state.syncing_to_server){
        this.handleSubjectiveFormSubmit();
      } 
    }, 2000); */
  }

  /* async componentWillUnmount() {
    this.subjectiveFieldServiceSubscription?.unsubscribe();
  } */
  
  handleSubjectivesData = async () => {
    const { patient } = this.state;
    const subjectivePromise = await Axios.get(SUBJECTIVE_INFRMATION_CLINICAL_NOTES({ reference_type: LS_SERVICE.get('reference_type'), reference_id: this.state.reference_id }));

    if (subjectivePromise.data.status) {
      let { data } = subjectivePromise.data;
      let { clinical_notes, clinical_notes_id, clinical_notes_date } = data;
      this.setState({
        clinical_notes: LS_SERVICE.get("clinical_notes_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("clinical_notes_" + patient.patient_id + "_" + patient.qms_token_id) : (clinical_notes || ''),
        clinical_notes_id: clinical_notes_id,
        clinical_notes_date: clinical_notes_date,
        visitDataLoaded: true,
      });
    }

    const vitalPDFVitalGraphPromise = await Axios.get(
      PDF_CONFIG_VITALS_GRAPH({
          reference_type: LS_SERVICE.get('reference_type'),
          reference_id: this.state.reference_id,
      })
  );

  if (vitalPDFVitalGraphPromise.data.status) {
      const { data } = vitalPDFVitalGraphPromise;
      let clinicalNotesSettings = data.data.details !== null ? (data.data.details.clinical_note_ids !== null ? data.data.details.clinical_note_ids : "") : "";

      const PDF_PRINT_OBJ = {};
      if (clinicalNotesSettings.length > 0) {
        clinicalNotesSettings.split(',').forEach(ppa => {
          PDF_PRINT_OBJ[ppa] = true
        })
      }
      this.setState({
        all_print: PDF_PRINT_OBJ
      });
    }
  }

  async getClinicalNotes() {
    const { reference_id } = this.state;
    const notes = await Axios.get(PATIENT_CLINICAL_NOTES({ reference_type: LS_SERVICE.get('reference_type'), reference_id: reference_id, type: 'soap' }));
    if (typeof notes.data != 'undefined' && typeof notes.data.data != 'undefined') {
      this.setState({
        clinicalNotesInfo: notes.data.data
      });
    }
  }

  handleOnChangeCheckbox = async event => {
    const { name, value, checked } = event.currentTarget;

    const { reference_id, all_print } = this.state;
    let dataArr = [];

    if(checked) {
      all_print[String(value)] = true;
    } else {
      all_print[String(value)] = false;
    }
    
    dataArr = all_print && Object.keys(all_print).filter((key, index) => all_print[key] ? key : '')

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id,
      selected_clinical_notes: dataArr.join(",")
    }

    const clinicalNotesPromise = await Axios.post(PDF_CONFIG_CLINICAL_NOTES_UPDATE, qs.stringify(PARAMS));
    if (clinicalNotesPromise.data.status) {
      this.setState({
        all_print
      });
    }

  }

  handlePrintAll = async (e) => {
    e.preventDefault();
    const { reference_id } = this.state;
    let dataArr = [];
    let all_print = [];
    if(this.state.clinical_notes_id != null){
      all_print[String(this.state.clinical_notes_id)] = true;
    }

    this.state.clinicalNotesInfo.map((ele) => {
      all_print[String(ele.id)] = true;
    })
    
    dataArr = all_print && Object.keys(all_print).filter((key, index) => all_print[key] ? key : '')

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id,
      selected_clinical_notes: dataArr.join(",")
    }

    const clinicalNotesPromise = await Axios.post(PDF_CONFIG_CLINICAL_NOTES_UPDATE, qs.stringify(PARAMS));
    if (clinicalNotesPromise.data.status) {
      this.setState({
        all_print
      });
    }
  }

  handleOnChange = event => {
    const { name, value } = event.currentTarget;
    const { patient } = this.state;
    var identifierValue = event.target.id;

    LS_SERVICE.set(event.currentTarget.name + "_" + patient.patient_id + "_" + patient.qms_token_id, event.currentTarget.value);

    this.setState((prevState) => ({
      [name]: value,
      detect_change: true,
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        initialText: value
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [event.target.id]: true
      }
    }), () => { /* this.handleConfirmBtnStatus(); */ })

  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'clinical_notes') {
      initialText = this.state.clinical_notes != undefined ? this.state.clinical_notes : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {

        // console.log("In IF info.identifier===state.speech calling with : ", this.state.speech)
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        }

      });
    } else {
      // console.log("IN Different MIC Previous Unclosed")
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
          this.handleRestartMic(info, initialText)
        }
        // console.log("State in init:",this.state.speech)

      });

    }
  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      }

      speechService.init(this.state.speech);
    });

  }

  handleOnFocus = (e) => {
    var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
    this.setState(prevState => ({
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        state: false,
        initialText: identifierValue === "clinical_notes" ? this.state.clinical_notes : ''
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [e.target.id]: false
      }
    }), () => {
      speechService.init(this.state.speech)
      // this.initSpeech({identifier: e.target.id})
    })

  }

  handleSubjectiveFormSubmit = async () => {
    const { clinical_notes, patient } = this.state;
    this.setState(prevState => ({
      syncing_to_server: true,
      /* speech: {
        ...prevState.speech,
        state: false,
      } */
    }), () => {
      //speechService.init(this.state.speech)
    });

    const { qms_token_id, patient_id } = patient;
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      clinical_notes: clinical_notes
    };

    let submitPromise;

    if (this.state.allowEdit) {
      submitPromise = await Axios.post(SUBJECTIVE_STORE_CLINICAL_NOTES, PARAMS);
      this.props.subjectiveChange('clinical_notes');
    }

    if (this.state.allowEdit || submitPromise.data?.status) {
      await this.setState({
        detect_change: false,
        syncing_to_server: false,
        clinical_notes_id: submitPromise.data.id ?? null
      })
      LS_SERVICE.set("clinical_notes_" + patient_id + "_" + qms_token_id, "");
    }
  }

  render() {
    const { patient, visitDataLoaded, clinicalNotesInfo } = this.state;
    return (
      <Fragment>
      <div className="card bg-white-clinical mb-3 mt-0 col-12">
        {visitDataLoaded && patient !== null ? (
          <>
            {/* <MyHcSpeechRecognition /> */}
            <label className='text-secondary label-pos'>Clinical Notes</label>
            <div className="card-body clinical-body">
              <div className='row'>
                <div className='col-12 d-flex mt-0'>
                  {/* <div className="custom-control custom-checkbox custom-control-inline mr-0">
                    <input type="checkbox" className="custom-control-input" name="print_today_note" id="print_today_note"
                    />
                    <label className="custom-control-label" htmlFor="print_today_note">
                    </label>
                    <a href="#" className='text-secondary text-underline'><u>Print Today's Note</u></a>
                  </div> */}
                  <p href="#" className='text-primary text-left mb-0 select_print'>Select to Print</p>
                  <a href="#" onClick={this.handlePrintAll} className='text-primary text-underline mx-3 ml-auto'>Print All</a>
                </div>
              </div>
              <div className='row'>
                <div className='col-auto d-flex align-items-center cus_width_clinical'>
                  <div className="custom-control custom-checkbox custom-control-inline mr-0">
                    <input type="checkbox" checked={this.state.all_print[String(this.state.clinical_notes_id)]} value={this.state.clinical_notes_id} disabled={this.state.clinical_notes_id == null} className="custom-control-input" name="clin_no" id="clin_no" onChange={this.handleOnChangeCheckbox} />
                    <label className="custom-control-label" htmlFor="clin_no">
                      <span className='text-dark font-today'>
                        {
                          this.state.clinical_notes_date && this.state.clinical_notes_date != null
                        ?
                          <Moment date={this.state.clinical_notes_date} format="D MMM YYYY" />
                        :
                          <Moment date={new Date()} format="D MMM YYYY" />
                        }
                      </span>
                    </label>
                  </div>
                </div>
                <div className='col'>
                  <div className="form-group micwrap normal mb-0">
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'clinical_notes' }) }}
                      className={(this.state.speech.identifier === 'clinical_notes' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <div className='d-flex'>
                    </div>
                    <textarea placeholder={'Type Here'} className={"form-control subjective-field show-placeholder"} id='clinical_notes' name='clinical_notes' disabled={!this.state.allowEdit}
                      value={this.state.clinical_notes} onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                  </div>
                </div>
              </div>
              
                {clinicalNotesInfo.map((element, index) => 
                  <div className='row mt-2' key={index}>
                    <div className='col-auto d-flex align-items-center cus_width_clinical'>
                      <div className="custom-control custom-checkbox custom-control-inline mr-0">
                        <input type="checkbox" checked={this.state.all_print[String(element.id)]} value={element.id} onChange={this.handleOnChangeCheckbox} className="custom-control-input" name={`clin_no_${element.id}`} id={`clin_no_${element.id}`}
                        />
                        <label className="custom-control-label" htmlFor={`clin_no_${element.id}`}><span className='sizegray  '><Moment date={element.created_at} format="D MMM YYYY" /></span>
                        </label>
                      </div>
                    </div>
                    <div className='col'>
                      <span className='sizegray'>{element?.description}</span>
                    </div>
                    <div>
                      <a className="nostyle-link mr-2" onClick={() => this.editNotes(element)} ><i className="icon-edit"></i></a>
                      <a className="nostyle-link mr-2" onClick={() => this.deleteNotes(element)} ><i className="icon_delete"></i></a>
                    </div>
                  </div>)}
              
            </div>
          </>
        ) : (
          <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
            {LOADER_RED}
          </div>
        )}
      </div>

        <Modal
          visible={this.state.deleteClinicalNotesModal}
          onCancel={() => this.closeModalPopup()}
          style={{ top: 40 }}
          title="Are you sure you want to delete notes?"
          closable={false}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancelOrderSet"
                onClick={() => this.closeModalPopup()}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 deleteOrderSet ml-1"
                disabled={this.state.spin_loading}
                id='deleteButton'
                onClick={() => this.deleteClinicalNotes()}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        ></Modal>

        <Modal
          visible={this.state.editClinicalNotesModal}
          onCancel={() => this.closeModalPopup()}
          style={{ top: 40 }}
          title="Edit clinical notes"
          closable={false}
          footer={false}
          className="emrfrm"
        >
          <Form
            ref={this.internalNotesFormRef}
            validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
            onFinish={this.updateClinicalNotes}
            className="internalNotes form-group"
            layout="vertical"
          >
            <div className="col-12 mb-2">
              <div className={this.state.clinical_notes_data.description ? 'antInputItem hasdata' : 'antInputItem'}>
                <Form.Item
                  label="Internal Note"
                  id="internal_note"
                  name="internal_note"
                  initialValue={this.state.clinical_notes_data.description}
                  rules={[{ required: true}]}
                >
                  <TextArea
                    className="form-control"
                    autoSize="true"
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="text-center mt-2">
              <Button type="default" className="mr-3 px-4" onClick={() => this.closeModalPopup()}>CANCEL </Button>
              <Button type="primary" htmlType="submit" className="px-4">UPDATE </Button>
            </Form.Item>
          </Form>
        </Modal>

      </Fragment>
    )
  }
}

export default ClinicalNotes;