import Axios from 'axios';
import React, { Component ,Fragment } from 'react';
import LS_SERVICE from '../../../utils/localStorage';
import {
  LOADER_RED,
  LOADER,
  SUBJECTIVE_INFRMATION_INTERNAL_NOTES,
  SUBJECTIVE_STORE_INTERNAL_NOTES,
  PATIENT_INTERNAL_NOTES,
  PATIENT_NOTES_DELETE,
  PATIENT_NOTES_UPDATE,
  HANDLEVALIDANTMESSAGESPATIENTAGE
} from '../../../utils/constant';
import { notification ,Modal,Form ,Input ,Button } from "antd";
import { Link } from 'react-router-dom';
import { headerService$, practiceService, speechService, speechTextService } from '../../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../../pages/speech-to-text/speech-recognition";
import { jQueryLabels, FixedCta } from '../../../utils/jQueryLabels';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';
const { TextArea } = Input;

class InternalNotes extends Component {

  constructor(props) {
		super(props);
		this.state = {
      detect_change: false,
      syncing_to_server: false,
      visitDataLoaded: false,
      patient : {
        hospital_id   : LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
        patient_id    : LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
        qms_token_id  : LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      },
      hospital_id: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
      patient_id: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      reference_id: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      allowEdit: props.allowEdit,
      internal_notes: '',
      internal_notes_date: '',
      internalNotesInfo: [],
      speech: {
        identifier: null,
        state: false
      },
      checkForEditsTrainingData: {
        "chief_complaint": undefined,
        "review_of_systems": undefined
      },
      spin_loading: false,
      internal_notes_data: {},
      deleteInternalNotesModal: false,
      editInternalNotesModal: false,
		};
    this.updateInternalNotes = this.updateInternalNotes.bind(this);
    this.closeModalPopup = this.closeModalPopup.bind(this);
    this.internalNotesFormRef = React.createRef();
    this.handleSubjectiveFormSubmit = debounce(this.handleSubjectiveFormSubmit, 1000);
	} 

  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }

  async componentWillUnmount() {
    this?.subscription?.unsubscribe();
    // if(this.state.detect_change){
      //await this.handleSubjectiveFormSubmit();
    // }
  }

  async componentDidUpdate() {
    setTimeout(() => {
      jQueryLabels();
      FixedCta();
      let Elem = document.querySelectorAll('.subjective-field');
      Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
    }, 100);
    if(this.state.detect_change && !this.state.syncing_to_server){
      await this.handleSubjectiveFormSubmit();
    } 
  }

  async componentDidMount() {
    await this.handleSubjectivesData();
    this.subscription = speechTextService.status().subscribe(async data => {
      const { patient } = this.state;
      if (this.state.speech.state) {
          if (this.state.speech.identifier === 'internal_notes') {
            this.setState({ internal_notes: data.text, detect_change: true }, () => { /* this.handleConfirmBtnStatus() */ });
            LS_SERVICE.set("internal_notes_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
            document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
          }
      }
    });
    this.getInternalNotes();

    /* setInterval(() => { 
      if(this.state.detect_change && !this.state.syncing_to_server){
        this.handleSubjectiveFormSubmit();
      } 
    }, 2000); */
  }

  handleSubjectivesData = async () => {
    const { patient } = this.state;
    const subjectivePromise = await Axios.get(SUBJECTIVE_INFRMATION_INTERNAL_NOTES({ reference_type: LS_SERVICE.get('reference_type'), reference_id: LS_SERVICE.get('reference_id') }));

    if (subjectivePromise.data.status) {
      let { data } = subjectivePromise.data;
      let { internal_notes, internal_notes_date } = data;

      this.setState({
        internal_notes: LS_SERVICE.get("internal_notes_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("internal_notes_" + patient.patient_id + "_" + patient.qms_token_id) : (internal_notes || ''),
        internal_notes_date: internal_notes_date,
        visitDataLoaded: true,
      });
    }
  }

  async getInternalNotes() {
    const { reference_id } = this.state;
    const notes = await Axios.get(PATIENT_INTERNAL_NOTES({ reference_type: LS_SERVICE.get('reference_type'), reference_id: reference_id, type: 'soap' }));
    if (typeof notes.data != 'undefined' && typeof notes.data.data != 'undefined') {
      this.setState({
        internalNotesInfo: notes.data.data
      });
    }
  }

  deleteInternalNotes() {
    let PARAMS = {
      type: 1,
      note_id: this.state.internal_notes_data.id
    }
    Axios.post(PATIENT_NOTES_DELETE, PARAMS).then(() => {
      this.getInternalNotes();
      this.closeModalPopup();
      notification.success({
        message: 'Internal notes deleted successfully',
        placement: 'topRight'
      });
    });
  }

  updateInternalNotes(values) {
    let PARAMS = {
      type: 1,
      note_id: this.state.internal_notes_data.id,
      description: values.internal_note
    }
    Axios.post(PATIENT_NOTES_UPDATE, PARAMS).then(() => {
      this.getInternalNotes();
      this.closeModalPopup();
      notification.success({
        message: 'Internal notes updated successfully',
        placement: 'topRight'
      });
    });
  }

  deleteNotes(element) {
    this.setState({
      internal_notes_data: element,
      deleteInternalNotesModal: true
    })
  }

  editNotes(element) {
    this.setState({
      internal_notes_data: element,
      editInternalNotesModal: true
    })
  }

  closeModalPopup() {
    this.setState({
      internal_notes_data: {},
      deleteInternalNotesModal: false,
      editInternalNotesModal: false,
    })
  }


  handleOnChange = event => {
    const { name, value } = event.currentTarget;
    const { patient } = this.state;
    var identifierValue = event.target.id;

    LS_SERVICE.set(event.currentTarget.name + "_" + patient.patient_id + "_" + patient.qms_token_id, event.currentTarget.value);

    this.setState((prevState) => ({
      [name]: value,
      detect_change: true,
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        initialText: value
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [event.target.id]: true
      }
    }), () => { /* this.handleConfirmBtnStatus(); */ })

  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'internal_notes') {
      initialText = this.state.internal_notes != undefined ? this.state.internal_notes : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {

        // console.log("In IF info.identifier===state.speech calling with : ", this.state.speech)
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        }

      });
    } else {
      // console.log("IN Different MIC Previous Unclosed")
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
          this.handleRestartMic(info, initialText)
        }
        // console.log("State in init:",this.state.speech)

      });

    }
  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      }

      speechService.init(this.state.speech);
    });

  }

  handleOnFocus = (e) => {
    var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
    this.setState(prevState => ({
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        state: false,
        initialText: identifierValue === "internal_notes" ? this.state.internal_notes : ''
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [e.target.id]: false
      }
    }), () => {
      speechService.init(this.state.speech)
      // this.initSpeech({identifier: e.target.id})
    })

  }

  handleSubjectiveFormSubmit = async () => {
    const { internal_notes, patient } = this.state;
    this.setState(prevState => ({
      syncing_to_server: true,
      /* speech: {
        ...prevState.speech,
        state: false,
      } */
    }), () => {
      //speechService.init(this.state.speech)
    });

    const { qms_token_id, patient_id } = patient;
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      internal_notes: internal_notes
    };

    let submitPromise;

    if (this.state.allowEdit) {
      submitPromise = await Axios.post(SUBJECTIVE_STORE_INTERNAL_NOTES, PARAMS);
      this.props.subjectiveChange('internal_notes');
    }

    if (this.state.allowEdit || submitPromise.data?.status) {
      await this.setState({
        detect_change: false,
        syncing_to_server: false,
      })
      LS_SERVICE.set("internal_notes_" + patient_id + "_" + qms_token_id, "");
    }
  }

  render() {
    const { patient, visitDataLoaded, internalNotesInfo } = this.state;
    return (
      <Fragment>
      <div className='row'>
        <div className='col-xl-12 col-lg-4'>
          <div className="card bg-white-clinical col-12 mb-2">
            {visitDataLoaded && patient !== null ? (
              <>
                {/* <MyHcSpeechRecognition /> */}
                <label className='text-dark label-pos bold-500'>INTERNAL NOTES (NOT TO BE PRINTED)</label>
                <div className="card-body clinical-body">
                  <div className='row mt-0'>
                    <div className='col-auto d-flex align-items-center cus_width_internal'>
                      <span className='text-dark font-today'>
                        {
                          this.state.internal_notes_date && this.state.internal_notes_date != null
                        ?
                          <Moment date={this.state.internal_notes_date} format="D MMM YYYY" />
                        :
                          <Moment date={new Date()} format="D MMM YYYY" />
                        }
                      </span>
                    </div>
                    <div className='col'>
                      <div className="form-group micwrap normal mb-0">
                        {this.state.allowEdit ? <Link
                          onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'internal_notes' }) }}
                          className={(this.state.speech.identifier === 'internal_notes' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                        <div className='d-flex'>
                        </div>
                        <textarea placeholder={'Type Here'} className={"form-control subjective-field show-placeholder"} id='internal_notes' name='internal_notes' disabled={!this.state.allowEdit}
                          value={this.state.internal_notes} onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                      </div>
                    </div>
                  </div>
                  
                    {internalNotesInfo.map((element, index) => 
                      <div className='row mt-2' key={index}>
                        <div className='col-auto d-flex align-items-center cus_width_internal'>
                          <span className='sizegray'><Moment date={element.created_at} format="D MMM YYYY" /></span>
                        </div>
                        <div className='col'>
                          <span className='sizegray'>{element?.description}</span>
                        </div>
                        <div>
                          <a className="nostyle-link mr-2" onClick={() => this.editNotes(element)} ><i className="icon-edit"></i></a>
                          <a className="nostyle-link mr-2" onClick={() => this.deleteNotes(element)} ><i className="icon_delete"></i></a>
                        </div>
                      </div>
                    )}
                  
                </div>
              </>
            ) : (
              <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
                {LOADER_RED}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
          visible={this.state.deleteInternalNotesModal}
          onCancel={()=>this.closeModalPopup()}
          style={{ top: 40 }}
          title="Are you sure you want to delete notes?"
          closable={false}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancelOrderSet"
                onClick={()=>this.closeModalPopup()}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 deleteOrderSet ml-1"
                disabled={this.state.spin_loading}
                id='deleteButton'
                onClick={()=>this.deleteInternalNotes()}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        ></Modal>

        <Modal
          visible={this.state.editInternalNotesModal}
          onCancel={() => this.closeModalPopup()}
          style={{ top: 40 }}
          title="Edit internal notes"
          closable={false}
          footer={false}
          className="emrfrm"
        >
          <Form
            ref={this.internalNotesFormRef}
            validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
            onFinish={this.updateInternalNotes}
            className= "internalNotes form-group"
            layout="vertical"
          >
            <div className="col-12 mb-2">
              <div className= {this.state.internal_notes_data.description ? 'antInputItem hasdata' : 'antInputItem'}>
                <Form.Item
                  label="Internal Note"
                  id="internal_note"
                  name="internal_note"
                  initialValue={this.state.internal_notes_data.description}
                  rules={[{ required: true}]}
                >
                  <TextArea
                    className="form-control"
                    autoSize="true"
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="text-center mt-2">
              <Button type="default" className="mr-3 px-4" onClick={() => this.closeModalPopup()}>CANCEL </Button>
              <Button type="primary" htmlType="submit" className="px-4">UPDATE </Button>
            </Form.Item>
          </Form>
        </Modal>

      </Fragment>
    )
  }
}

export default InternalNotes;