import React from 'react';
import { Line, Scatter, Chart } from 'react-chartjs-2';
import { DURATION_TYPE_SINGULAR, LOADER_RED, PDF_CONFIG_KEYFINDINGS_GRAPH_UPDATE, VITAL_Array } from '../../utils/constant';
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import qs from 'qs';
import moment from "moment";
import { data } from 'jquery';

class KeyFindingsChartNew extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartData: props?.data,
            chartType: props?.chartType,
            chartDataMapped: {},
            heading: []
        }
    }


    componentDidMount() {
        this.handleDynamicDataCreation();
    }

    GET_RANGE_CLASS = (status) => {
        if (status == "-") return "";
        let vitalRange = "";
        //normal range
        if (status == 'N')
          vitalRange = '#28a745';
    
        //abnormal range
        if (
          status == 'A' || status == 'P'
        )
          vitalRange = "#eb2d2e";
    
        //critical range
        if (
          status == 'L' || status == 'H'
        )
          vitalRange = "#ffc107";
    
        return vitalRange;
      };


    handleDynamicDataCreation() {

        let data;
        let chartDataMapped = {};
        let finalArray = [];

        if (this.state.chartType == 'finding-group') {
            data = this.state.chartData.testData;
            if (data && Object.entries(data)?.length > 0) {
                for (const [key, value] of Object.entries(data)) {
                    let dataObj = {};
                    dataObj['name'] = key;
                    let valuesData = value;
                    if (Object.keys(valuesData)?.length > 0) {
                        let datesArray = [];
                        let valuesArray = [];
                        let colorArray = [];
                        for (const [skey, svalue] of Object.entries(valuesData)) {
                            valuesArray.push(svalue.test_value);
                            colorArray.push(this.GET_RANGE_CLASS(svalue.isNormal));
                            datesArray.push(skey);
                        }
                        dataObj['val'] = valuesArray.reverse();
                        dataObj['col'] = colorArray.reverse();
                        var min_of_Array = Math.min.apply(Math, valuesArray);
                        var max_of_Array = Math.max.apply(Math, valuesArray);
                        dataObj['min'] = min_of_Array;
                        dataObj['max'] = max_of_Array;
                        dataObj['date'] = datesArray.reverse();
                    }
                    finalArray.push(dataObj);
                }
            }

        } else if (this.state.chartType == "individual") {
            let test_name = this.state.chartData?.testName;
            data = this.state.chartData?.testDataIndividual?.[test_name]
            if (data && typeof data != undefined && Object.entries(data).length > 0) {
                let testObj = {}

                let valArray = Object.values(data);
                let val = [];
                let colArray = [];
                for(const ele of valArray){
                    val.push(ele.test_value);
                    colArray.push(this.GET_RANGE_CLASS(ele.isNormal));
                }

                
                testObj['name'] = test_name;
                testObj['date'] = Object.keys(data).reverse();
                testObj['val'] = val.reverse();
                testObj['col'] = colArray.reverse();
                testObj['min'] = Math.min.apply(Math, val);
                testObj['max'] = Math.max.apply(Math, val);
                finalArray.push(testObj);
            }

        }

        if (finalArray?.length > 0) {
            finalArray.map(ele => {
                chartDataMapped[ele.name] = {
                    ['data_' + ele.name]: {
                        labels: ele.date,
                        datasets: [
                            {
                                label: '',
                                backgroundColor: 'rgb(102 102 102)',
                                borderColor: 'rgb(102 102 102)',
                                pointBackgroundColor: ele.col,
                                pointBorderColor: ele.col,
                                borderWidth: 1,
                                fill: false,
                                spanGaps: true,
                                data: ele.val
                            }
                        ]
                    },
                    ['options_' + ele.name]: {
                        responsive: true,
                        animation: {
                            duration: 0
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            datalabels: {
                                font: {
                                    size: 0
                                }
                            }
                        },
                        title: {
                            display: false,
                            text: ele.name,
                            fontSize: 15
                        },
                        elements: {
                            line: {
                                tension: 0,
                            },
                        },
                        legend: {
                            position: "bottom",
                            display: false,
                            labels: {
                                fontSize: 11,
                                boxWidth: 6,
                                //fontColor: '#333333',
                                // usePointStyle: true,
                            },
                        },

                        scales: {
                            x: {
                                display: true,
                                /* scaleLabel: {
                                  display: false,
                                  labelString: '',
                                }, */
                                ticks: {
                                    font: {
                                        size: 11
                                    }
                                }
                            },
                            y: {
                                position: 'left',
                                display: true,
                                /* scaleLabel: {
                                  display: false,
                                  labelString: '',
                                }, */
                                suggestedMin: ele.min,
                                suggestedMax: ele.max,
                                ticks: {
                                    font: {
                                        size: 11
                                    },
                                    precision: 1,
                                    stepSize: 1,
                                },
                            }
                        },
                    }
                };
            })
        }

        let heading = Object.keys(chartDataMapped);

        this.setState({ chartDataMapped: chartDataMapped, heading: heading });
    }

    render() {

        const { chartType, heading } = this.state;
        return (
            <>
                {
                    chartType == 'finding-group' ?
                        <div className="col-12">
                            <h6 style={{ padding: '0.5rem 0rem', marginBottom: '0', fontWeight: '700' }}>{this.state.chartData.testName}</h6>
                        </div> : null
                }


                <div className={`chartCanvasWrapper ${chartType == 'individual' ? 'chartCanvasWrapperSmall' : 'w-100'}`} >
                    {
                        typeof heading != 'undefined' && heading.length > 0 ?
                            heading.map((ch, index) => {
                                let showObj = this.state.chartDataMapped[ch];
                                let showData = showObj[`data_${ch}`];
                                let showOptions = showObj[`options_${ch}`];
                                return (
                                    <React.Fragment>
                                        <div className="chartCanvas" key={index}>
                                            <h4>{ch}</h4>
                                            <Line
                                                data={showData}
                                                options={showOptions}
                                            />
                                        </div>
                                    </React.Fragment>
                                )
                            }) : "No Chart Data"
                    }
                </div>
            </>
        )
    }

}

export default KeyFindingsChartNew;